import { render, staticRenderFns } from "./recordList.vue?vue&type=template&id=a1b0b792&scoped=true&"
import script from "./recordList.vue?vue&type=script&lang=js&"
export * from "./recordList.vue?vue&type=script&lang=js&"
import style0 from "./recordList.vue?vue&type=style&index=0&id=a1b0b792&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a1b0b792",
  null
  
)

export default component.exports