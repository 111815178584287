<template>
  <div>
    <el-card class="searchContainer">
      <div class="desc">筛选查询</div>
      <el-row :gutter="20" class="query">
        <el-col :span="8">
          <span class="searchText">商家昵称</span>
          <el-input
            class="searchInput"
            placeholder="商家昵称"
            @keyup.enter.native="getList"
            v-model.trim="searchValue.businessName"
            clearable
          >
          </el-input>
        </el-col>

        <el-col :span="8">
          <span class="searchText">商家ID</span>
          <el-input
            class="searchInput"
            oninput="value=value.replace(/[^0-9]/g,'')"
            placeholder="商家ID"
            @keyup.enter.native="getList"
            v-model="searchValue.businessId"
            clearable
          >
          </el-input>
        </el-col>
        <el-col :span="8">
          <span class="searchText">提现状态</span>
          <el-select v-model="searchValue.verifyStatus" placeholder="请选择">
            <el-option label="同意" value="1"> </el-option>
            <el-option label="拒绝" value="2"> </el-option>
          </el-select>
        </el-col>
        <!-- <el-form inline> -->
        <!-- <bm-areaSelect v-model="searchValue.areaId"></bm-areaSelect> -->
        <!-- </el-form> -->
      </el-row>
      <el-row class="query">
        <el-col :span="16">
          <span class="searchText">时间</span>
          <el-date-picker
            value-format="timestamp"
            v-model="date"
            type="datetimerange"
            :default-time="['00:00:00', '23:59:59']"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-col>
        <el-col :span="8">
          <el-button type="info" @click="resetSearch()">重置</el-button>
          <el-button type="primary" @click="getList()"> 搜索</el-button>
        </el-col>
      </el-row>
    </el-card>
    <el-card class="tableContainer">
      <div slot="header" class="clearfix">
        <span>数据列表</span>
      </div>
      <el-table :data="list" v-loading="loading" style="width: 100%">
        <el-table-column align="center" prop="businessId" label="商家ID"> </el-table-column>
        <el-table-column label="商家信息" align="center">
          <template slot-scope="scope">
            <div class="product-info">
              <img class="avatar" :src="scope.row.businessLogo" />
              <span class="title"> {{ scope.row.businessName }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="amount" label="申请提现金额（元）"> </el-table-column>
        <el-table-column align="center" prop="verifyStatus" label="提现状态">
          <template slot-scope="scope">
            {{ scope.row.verifyStatus === 2 ? '拒绝 ' : '通过' }}
          </template>
        </el-table-column>
        <el-table-column align="center" prop="storeNames" label="申请门店"> </el-table-column>
        <el-table-column align="center" prop="operatorMemberName" label="申请人员"> </el-table-column>
        <el-table-column align="center" prop="currentTotalAmount" label="申请时间">
          <template slot-scope="scope">
            {{ scope.row.createTime | formatTime }}
          </template>
        </el-table-column>

        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.verifyStatus === 2"
              @click="checkRejectReason(scope.row)"
              type="text"
              size="small"
            >
              查看拒绝原因</el-button
            >
            <el-button v-else @click="checkDetail(scope.row)" type="text" size="small"> 查看详情</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="footer">
        <el-pagination
          :current-page="currentPage"
          :page-sizes="pageSizes"
          :page-size="pageSize"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
          :total="total"
          layout="total, sizes, prev, pager, next, jumper"
          class="pagination"
        >
        </el-pagination>
        <div></div>
      </div>
    </el-card>

    <el-dialog :close-on-click-modal="false" title="同意提现" :visible.sync="approve.show" width="700px">
      <div class="paymentType">
        <div class="item">
          <el-radio v-model="approve.detail.paymentType" disabled :label="1">线上结算</el-radio>
          <div>
            1.如果付款到账时效为1-3日，最快次日到账<br />
            2.真实结算状态以微信商户付款状态为准，平台线上结算目前只提交到微信成功，不代表真正付款成功，如果微信商户付款失败，则还需要线下付款给商户
          </div>
        </div>
        <div class="item">
          <el-radio v-model="approve.detail.paymentType" disabled :label="2">线下结算</el-radio>
          <div>即平台在同意申请后，把状态改为已结算。但仍需要人工转账，不会从商户号转钱。</div>
        </div>
      </div>

      <el-card class="detail">
        <div class="vendor">
          <div class="item">
            <div>商家:{{ approve.detail.businessName }}</div>
            <div class="store">提现门店: {{ approve.detail.storeNames }}</div>
          </div>
          <div class="next">
            <div>申请人: {{ approve.detail.operatorMemberName }}</div>
            <div class="time">申请时间: {{ approve.detail.createTime | formatTime }}</div>
          </div>
        </div>
        <div class="withdrawMoney">
          <div class="money" v-if="approve.detail.collectionType == 1">
            <!-- <div>开户名:{{ approve.detail.bankCode }}</div> -->
            <div>开户行:{{ approve.detail.bankName }}</div>

            <div>银行卡号:{{ approve.detail.encBankNo }}</div>
          </div>
          <div class="money">提现金额:{{ approve.detail.amount }}</div>
        </div>
        <div class="collection">
          <div class="item">
            <div>收款人:{{ approve.detail.payeeName }}</div>
          </div>
          <div class="next">
            <div>手机号: {{ approve.detail.payeeMobile }}</div>
          </div>
        </div>
      </el-card>

      <span slot="footer" class="dialog-footer">
        <el-button @click="approve.show = false">关闭</el-button>
      </span>
    </el-dialog>

    <!-- 查看拒绝原因  -->
    <el-dialog :close-on-click-modal="false" title="拒绝原因" :visible.sync="rejectReasonDialog" width="350px">
      {{ rejectReasonText }}
      <span slot="footer" class="dialog-footer">
        <el-button @click="rejectReasonDialog = false">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { pagination } from '@/mixin/pagination'
export default {
  mixins: [pagination],
  data() {
    return {
      date: [],
      searchValue: {
        businessId: '',
        businessName: '',
        startTime: '',
        endTime: '',
        verifyStatus: '1'
      },
      list: [],
      rejectReasonDialog: false,
      rejectReasonText: '',
      approve: { show: false, paymentStatus: 1, verifyStatus: 1, paymentId: 1, detail: [] }
    }
  },

  methods: {
    checkRejectReason(row) {
      this.rejectReasonDialog = true
      this.rejectReasonText = row.denialNotes
    },
    checkDetail(row) {
      this.approve.detail = { ...row }
      this.approve.show = true
    },
    // 请求后端获取提现审核列表
    getList(val) {
      this.loading = true
      this.currentPage = val === true ? this.currentPage : 1

      let params = {
        params: {
          ...this.searchValue,
          startTime: this.date ? this.date[0] : '',
          endTime: this.date ? this.date[1] : '',
          page: this.currentPage,
          size: this.pageSize
        }
      }
      this.$http
        .get('/boom-center-statistics-service/sysAdmin/finance/payment', params)

        .then(res => {
          this.list = res.records
          this.total = parseInt(res.total)
        })
        .catch(err => {
          this.$message.error(err.msg || '操作失败')
        })
        .finally(() => {
          this.loading = false
        })
    },
    resetSearch() {
      this.searchValue = {
        businessId: '',
        businessName: '',
        startTime: '',
        endTime: '',
        verifyStatus: '1'
      }
      this.date = []
      this.getList()
    }
  }
}
</script>

<style lang="less" scoped>
.query {
  margin-top: 20px;
}

.tableContainer .footer {
  justify-content: center;
}

.product-info {
  display: flex;
  align-items: center;
  .avatar {
    width: 40px;
    height: 40px;
    margin-right: 10px;
    vertical-align: middle;
  }
  .title {
    vertical-align: middle;
  }
}

.paymentType {
  display: flex;
  .item {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 330px;
    margin: 20px;
    padding: 10px;
    border-radius: 18px;
    box-shadow: 0 2px 6px 0 #999;
  }
}
.detail {
  background-color: #99999930;
  .vendor {
    display: flex;
    justify-content: space-between;
    .item {
      .store {
        margin-top: 10px;
      }
    }
    .next {
      width: 210px;
      .time {
        margin-top: 10px;
      }
    }
  }
  .withdrawMoney {
    display: flex;
    margin: 20px 0;
    justify-content: space-between;

    .money {
      width: 210px;
    }
  }

  .collection {
    display: flex;
    justify-content: space-between;
    .next {
      width: 210px;
    }
  }
}
</style>
